import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // css manage start
  encapsulation: ViewEncapsulation.None,
  // css manage end
})
export class AppComponent {
  title = 'foundation';
  onActivate(event: any) {
    window.scroll(0, 0);
    document.body.scrollTop = 0;
    //document.querySelector('body').scrollTo(0,0)
  }
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }
  ngOnInit(): void {
  }
}


