import { NgModule } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { HttpInterceptorService } from "./http-interceptor.service";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
} from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { from, Observable } from "rxjs";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import en from "@angular/common/locales/en";
import { DemoNgZorroAntdModule } from "./ng-zorro-antd.module";
import { ToastrModule } from "ngx-toastr";
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    let langLocal = localStorage.getItem("lang");
    if (langLocal == undefined) {
      localStorage.setItem("lang", "en");
    } else {
      localStorage.setItem("lang", langLocal);
    }
    return from(import(`../assets/i18n/${localStorage.getItem("lang")}.json`));
  }
}

registerLocaleData(en);
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ReactiveFormsModule,
    DemoNgZorroAntdModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot(),   
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        deps: [HttpClient],
        useClass: WebpackTranslateLoader,
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_US },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
